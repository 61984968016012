.svgicons-module__bjRlZG__up {
  transform: rotate(-90deg);
}

.svgicons-module__bjRlZG__upArrow {
  transform: rotate(0);
}

.svgicons-module__bjRlZG__down {
  transform: rotate(90deg);
}

.svgicons-module__bjRlZG__downArrow, .svgicons-module__bjRlZG__left {
  transform: rotate(180deg);
}

.svgicons-module__bjRlZG__leftArrow {
  transform: rotate(-90deg);
}

.svgicons-module__bjRlZG__right {
  transform: rotate(0);
}

.svgicons-module__bjRlZG__rightArrow {
  transform: rotate(90deg);
}

.svgicons-module__bjRlZG__highlightOnHover:hover {
  fill: var(--highlight-color);
}

.svgicons-module__bjRlZG__svgCloseHover:hover {
  stroke: var(--secondary-1);
}

.link-module__jDo5SG__baseLink {
  color: var(--gold);
  font-family: sans-serif;
  font-size: var(--font-size-md);
  letter-spacing: normal;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: .25rem;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  line-height: 1.25;
  text-decoration: none;
  transition: all 80ms ease-in-out;
}

.link-module__jDo5SG__linkAsButton {
  background-color: var(--primary-1);
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  line-height: 1;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
}

.link-module__jDo5SG__linkAsButton span {
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  padding-top: .1875rem;
  transition: all .1s ease-in-out;
}

.link-module__jDo5SG__linkAsButton:hover span {
  border-bottom: 3px solid var(--primary-2);
  transition: all .1s ease-in-out;
}

.link-module__jDo5SG__linkAsButton.link-module__jDo5SG__secondaryButton {
  background-color: var(--primary-3);
  color: var(--primary-2);
}

.link-module__jDo5SG__baseLink:hover {
  text-underline-offset: 1px;
  text-decoration: underline;
}

.link-module__jDo5SG__unstyledLink {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  letter-spacing: normal;
  color: var(--primary-2);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: 1.25;
  text-decoration: none;
}

.link-module__jDo5SG__arrowStyled {
  width: -moz-fit-content;
  width: fit-content;
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  letter-spacing: normal;
  color: var(--primary-2);
  word-break: keep-all;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: 1.25;
  text-decoration: none;
  transition: all .1s ease-in-out;
  display: flex;
}

.link-module__jDo5SG__arrowStyled:hover {
  text-decoration: underline;
  transition: all .1s ease-in-out;
}

.link-module__jDo5SG__arrowStyled svg {
  fill: rgba(0, 0, 0, 0);
  transition: all .1s ease-in-out;
}

.link-module__jDo5SG__arrowStyled:hover svg {
  fill: var(--primary-2);
  transition: all .1s ease-in-out;
}

.link-module__jDo5SG__baseLinkBlack {
  color: var(--primary-2);
}

.link-module__jDo5SG__small {
  height: 42px;
}

.link-module__jDo5SG__medium {
  height: 52px;
}

.link-module__jDo5SG__large {
  height: 64px;
}

.link-module__jDo5SG__fit-content {
  height: -moz-fit-content;
  height: fit-content;
}

